/* src/App.css */
.login-container {
  width: 400px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

.login-container h1 {
  margin-bottom: 20px;
}
